
	import { Component, Vue, Watch } from "vue-property-decorator";
	import {
		Button,
		ButtonGroup,
		Checkbox,
		CheckboxGroup,
		DatePicker,
		Form,
		FormItem,
		Input,
		InputNumber, Message,
		Modal,
		Option,
		Page,
		Radio,
		RadioGroup,
		Select,
		Table
	} from "view-design";
	import request from "@/request";
	import moment from "moment";
	import qs from "qs";
	import Loading from "@/components/loading/Loading.vue";
	import { CreateElement, VNode } from "vue";

	@Component({
		components: {
			Modal,
			Form,
			FormItem,
			Input,
			Radio,
			RadioGroup,
			Checkbox,
			CheckboxGroup,
			DatePicker,
			Select,
			InputNumber,
			Option,
			Table,
			Page,
			Loading,
			ButtonGroup,
			Button,
			Message
		}
	})
		export default class MaintenancePlan extends Vue {
            delAuth = false;
            canAdd:boolean = false;
            isDrawBack:boolean = false;
            showModal:boolean = false;
            addloading: boolean = true;
            myheight = 500;
            title:string = "添加计划";
            template:{ name:string, id:number }[] = [];
            type:string[] = [ "物业", "外包"];
            facility:{ name:string, id:number }[] = [];
            deviceType = [];
            form:any = {
                name: "",
                before_days: 0,
                template: 0,
                type: [],
                facility: [],
                loopTime: [ new Date(), new Date(Date.now() + 3600 * 1000 * 24 * 365) ],
                duration: [ new Date(), new Date(Date.now() + 3600 * 1000 * 24 * 365) ],
                loop: 0,
                frequency: 1,
                frequencyUnit: "月",
                devicetype: []
            };
            readonly options = {
                disabledDate (date:Date):boolean {
                    return date && date.valueOf() < Date.now();
                }
            };
            page:number = 1;
            columns = [
                {
                    title: "楼宇",
                    key: "facilityName",
                    align: "center",
                    width: 80
                },
                {
                    title: "描述",
                    key: "name",
                    align: "center",
                    ellipsis: true,
                    width: 180
                },
                {
                    title: "起止时间",
                    key: "duration",
                    align: "center",
                    width: 170
                },
                {
                    title: "循环时间",
                    key: "loop",
                    align: "center",
                    width: 170
                },
                // {
                //     title: "类型",
                //     align: "center",
                //     key: "tp"
                // },
                {
                    title: "负责班组",
                    key: "template_name",
                    // width: 150,
                    align: "center",
                    ellipsis: true,
                    tooltip: true
                },
                {
                    title: "操作",
                    width: 170,
                    align: "center",
                    render: (h:CreateElement, params:any):VNode => {
                        if (params.row.button) {
                            return h("p",
                                {
                                    style: {
                                        "display": "flex",
                                        "justify-content": "center",
                                        "cursor": "pointer"
                                    }
                                },
                                [
                                    h("img", {
                                        style: {
                                            "margin": "0 8px 0 0"
                                        },
                                        attrs: {
                                            src: require("@/assets/img/6.png"),
                                            title: "编辑"
                                        },
                                        on: {
                                            click: () => {
                                                this.edit(params.row);
                                            }
                                        }
                                    }),
                                    h("img", {
                                        style: {
                                            "margin": "0 8px 0 0",
                                            "display": params.row.delAuth ? "" : "none"
                                        },
                                        attrs: {
                                            src: require("@/assets/img/2.png"),
                                            title: "删除"
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row);
                                            }
                                        }
                                    }),
                                    ((buttons) => {
                                        if (buttons) {
                                            return buttons.map((c:string) => {
                                                // 列表
                                                let imgname = require("@/assets/img/list.png");
                                                if (c == "提交") {
                                                    imgname = require("@/assets/img/5.png");
                                                } else if (c == "退回") {
                                                    imgname = require("@/assets/img/1.png");
                                                } else if (c == "通过审核") {
                                                    imgname = require("@/assets/img/4.png");
                                                } else if (c == "批准") {
                                                    imgname = require("@/assets/img/3.png");
                                                }
                                                return h("img", {
                                                    style: {
                                                        "margin": "0 8px 0 0"
                                                    },
                                                    attrs: {
                                                        src: imgname,
                                                        title: c
                                                    },
                                                    on: {
                                                        click: () => {
                                                            // @ts-ignore
                                                            Message.info("提交中，请稍候");
                                                            let vm = this;
                                                            request({
                                                                url: "/device/projecttasktype/" + params.row.id + "/",
                                                                data: {
                                                                    button: c
                                                                },
                                                                method: "patch"
                                                            }).then(() => {
                                                                let page = this.getPage();
                                                                this.pageChange(page);
                                                            });
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                    })(params.row.button)
                            ]);
                        } else {
                            return h("p",
                                {
                                    style: {
                                        "display": "flex",
                                        "justify-content": "center",
                                        "cursor": "pointer"
                                    }
                                },
                                [
                                    h("img", {
                                        style: {
                                            "margin": "0 8px 0 0"
                                        },
                                        attrs: {
                                            src: require("@/assets/img/list.png"),
                                            title: "查看"
                                        },
                                        on: {
                                            click: () => {
                                                this.edit(params.row, true);
                                            }
                                        }
                                    }),
                                    h("img", {
                                        style: {
                                            "display": params.row.delAuth ? "" : "none"
                                        },
                                        attrs: {
                                            src: require("@/assets/img/2.png"),
                                            title: "删除"
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row);
                                            }
                                        }
                                    })
                                ]
                            );
                        }
                    }
                }
            ];
            dataSource:any[] = [];
            tableLoading:boolean = true;
            total:number = 0;
            disable:boolean = true;
            private id:number = 0;
            pageSize = 20;
		// get height ():number {
		// 	return document.body.clientHeight - 224;
		// }

		// get pageSize ():number {
		// 	return Math.floor((this.height - 36) / 40);
		// }

		getPage () {
			return this.page;
		}

		add () {
			this.disable = false;
			this.id = 0;
			this.form = {
				name: "",
				template: this.template[ 0 ].id,
				type: [],
				facility: [1],
				loopTime: [ new Date(Date.now() + 3600 * 1000 * 24 * 1), new Date(Date.now() + 3600 * 1000 * 24 * 365) ],
				duration: [ new Date(Date.now() + 3600 * 1000 * 24 * 1), new Date(Date.now() + 3600 * 1000 * 24 * 365) ],
				loop: 0,
				frequency: 1,
				frequencyUnit: "月",
				before_days: 0,
                devicetype: []
			};
			this.showModal = true;
			this.title = "添加计划";
		}
        getContent (id) {
            return new Promise((resolve) => {
                request(`/device/projecttasktype/${ id }/`).then(({ data }) => {
                    resolve(data);
                });
            });
        }

		edit (row:any, flag:boolean = false) {
			this.showModal = true;
			this.disable = flag;
			this.title = flag ? "维保计划详情" : "修改维保计划";
			this.form.name = row.name;
			this.form.template = this.template.find(t => t.name === row.template_name)!.id;
			this.form.type = row.tp.length > 0 ? row.tp.split(",") : [];
			this.form.duration = [ new Date(row.firstEventstartTime), new Date(row.firstEventendTime) ];
			this.form.frequency = row.frequency;
			this.form.frequencyUnit = row.frequencyUnit;
			this.form.loopTime = [ new Date(row.startTime), new Date(row.endTime) ];
			this.form.facility = row.facilitys.map((t:string) => {
				const temp = t.match(/(?<=\/)\d+(?=\/)/);
				return temp ? parseInt(temp[ 0 ]) : "";
			});
			this.form.before_days = row.before_days;
			this.form.loop = row.iscircle ? 1 : 0;
			this.id = row.id;
            this.getContent(row.id).then((item:any) => {
                this.form.devicetype = item.devicetype ? item.devicetype : [];
            });
		}

		remove (row:any) {
			// @ts-ignore
			Modal.confirm({
				// title: "确认删除?",
				title: "<p class='del_text_wrap'>确认删除?</p>",
				loading: true,
				onOk: () => {
					request({
						url: "/device/taskopt/",
						data: {
							tid: row.id,
							opt: "del"
						},
						method: "post",
						transformRequest: [ function (data) {
							return qs.stringify(data, { encode: false, arrayFormat: "brackets" });
						} ]
					}).then(() => {
						// @ts-ignore
						Modal.remove();
						// @ts-ignore
						Message.info("删除成功");
						this.pageChange(this.page);
					});
				}
			});
		}
        initBtn () {
            setTimeout(() => {
                this.addloading = false;
                setTimeout(() => {
                    this.addloading = true;
                });
            }, 1000);
        }
		submit () {
			const info = this.form;
            if (!info.name) {
                this.initBtn();
                this.$Message.info("请填写名称");
                return;
            }
            if (info.devicetype.length === 0) {
                this.initBtn();
                this.$Message.info("请选择设备类型");
                return;
            }
            if (info.facility.length === 0) {
                this.initBtn();
                this.$Message.info("请选择楼宇");
                return;
            }
			let payload = {
				opt: /添加/.test(this.title) ? "create" : "change",
				name: info.name,
				template: info.template,
				frequency: info.frequency,
				frequencyUnit: info.frequencyUnit,
				facilitys: info.facility,
				firstEventstartTime: moment(info.duration[ 0 ]).format("YYYY-MM-DD"),
				firstEventendTime: moment(info.duration[ 1 ]).format("YYYY-MM-DD"),
				iscircle: info.loop === 1,
				tp: info.type.join("$"),
				daterange: [ moment(info.loopTime[ 0 ]).format("YYYY-MM-DD"), moment(info.loopTime[ 1 ]).format("YYYY-MM-DD") ],
				before_days: info.before_days,
                devicetype: this.form.devicetype
			};
			if (this.id > 0) {
				// @ts-ignore
				payload.id = this.id;
			}
			request({
				method: "post",
				url: "/device/taskopt/",
				data: payload,
				transformRequest: [ function (data) {
					return qs.stringify(data, { encode: false, arrayFormat: "brackets" });
				} ]
			}).then(() => {
				this.showModal = false;
				this.pageChange(this.page);
			});
		}
        getDeviceType () {
            request("/device/devicetype/?format=json").then((data) => {
                this.deviceType = data.data;
            });
        }
		pageChange (page:number) {
			this.page = page;
			this.tableLoading = true;
			request({
				url: "/device/projecttasktype/",
				params: {
					pagesize: this.pageSize,
					ordering: "-startTime",
					page
				}
			}).then(({ data }) => {
				this.tableLoading = false;
				this.dataSource = data.results.map((value:any) => ({
					...value,
					duration: value.firstEventstartTime + "~" + value.firstEventendTime,
					facilityName: value.facilitys_obj.map((t:any) => t.name).join(),
					loop: value.iscircle == false ? "" : value.startTime + "~" + value.endTime,
					tp: value.tp.split("$").join(),
                    delAuth: this.delAuth
				}));
				this.total = data.count;
				this.tableLoading = false;
			}).catch(() => {
				this.page--;
				this.pageChange(page - 1);
			});
		}
        getAuth () {
            return new Promise((resolve, reject) => {
                request("/user/self/").then(({ data }) => {
                    this.delAuth = data.data.superuser;
                    // console.log(this.delAuth, "this.delAuth");
                    resolve(data.data.permissions.indexOf("录入计划") > -1);
                });
            });
        }
        created () {
            this.getAuth().then((res:boolean) => {
                this.canAdd = res;
                this.pageChange(1);
            });
        }
		mounted () {
            this.getDeviceType();
			request({
				url: "/prj/facility/",
				params: {
					ordering: "sequence"
				}
			}).then(({ data }) => {
				this.facility = data.map((t:any) => ({
					id: t.id,
					name: t.name
				}));
                this.form.facility = [this.facility[0].id];
			});
			request({
				url: "/repair/rest/flowtemplate/",
				params: {
					flowtype: 2,
					"responsible_party!": 2
				}
			}).then(({ data }) => {
				this.template = data;
			});
            this.$nextTick(() => {
                this.myheight = document.querySelector("body").clientHeight - 183;
            });
		}
        @Watch("isDrawBack")
        onisDrawBackchange (val) {
            this.$store.commit("changeDraw", val);
        }
	}
